.advisory-card {
  width: 90%;
  border-radius: 15px;
  background: #fff;
  /* outline: 1px solid #90ee90; */
  box-shadow: 0 0 4px #19be8de8;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}
.card-img-top {
  width: 75% !important;
}
.protect-card {
  border-radius: 15px;
  background: #fff;
  box-shadow: 0 0 4px #19be8de8;

  /* flex-direction: column;
  align-items: center; */

  transition: all 0.3s ease-in-out;
}
.advisory-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon-wrapper {
  width: 55px;
  /* height: 60px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 10px;
  font-weight: 600;
  color: #000;
  margin-top: 8px;
}
