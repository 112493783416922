.card-description span {
  font-size: 10px;
}
.rsa-text {
  opacity: 0.6;
}
@media (max-width: 768px) {
  .card-description {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .card-description {
    font-size: 14px;
  }
}
