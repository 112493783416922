/* src/style.css */

.custome-textfont {
  font-size: 12px;
}
.custom-card {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  /* overflow: hidden; */
  background-color: #fff;
}
.packages_card {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}
.custom-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.custom-card .card-title {
  margin-bottom: 10px;
}

.custom-card .card-text {
  color: #6c757d;
}
.custom-card .card-title {
  font-weight: 700;
}
.dark-border {
  border: 1px solid #333; /* Darker border color */
}

.rounded-border {
  border-radius: 20px; /* Adjust to make the corners more rounded */
}
.coverage-bg {
  background-color: #a6d4dc;
}
.position-relative {
  position: relative;
}

.pointing-person {
  width: 100px; /* Adjust the size as necessary */
  height: auto;
  position: absolute;
}

.pointing-left {
  transform: translateX(-40%);
}

.pointing-right {
  transform: translateX(24%);
  right: 0;
}

/* src/components/Icon.css */
.icon-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  padding: 15px;
  margin: 10px;
}

.icon-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 12px 24px rgba(0, 0, 0, 0.2);
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  border-radius: 50%;
  box-shadow: 6px 6px 12px #cbcbcb, -6px -6px 12px #ffffff;
}

.icon-img {
  width: 35px;
  height: 35px;
}

.icon-label {
  font-weight: 700;
  font-size: 12px;
  margin-top: 10px;
}
